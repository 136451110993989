<template>
    <st-page layout="tab" :showHeader="false">
        <application-activities
            :items="activities"
            :record="record"
            :hasPermissionToReadStaff="hasPermissionToReadStaff"
            @updateActivities="updateActivities"
        />
    </st-page>
</template>

<script>
import ApplicationActivities from '@/modules/applications/components/view-application/activities/ApplicationActivities';
import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ApplicationActivityTab',
    components: {
        ApplicationActivities,
    },
    props: {
        applicationId: String,
    },
    data() {
        return {
            polling: null,
        }
    },
    computed: {
        ...mapGetters({
            activities: 'applications/activity/activities',
            currentUser: 'auth/currentUser',
            record: 'applications/form/record'
        }),
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
    },
    methods: {
        ...mapActions({
            getActivities: 'applications/activity/getActivities'
        }),
        updateActivities(applicationId) {
            this.getActivities(applicationId);
        }
    },
    async mounted() {
        if (this.record && this.record.id && !this.polling) {
            this.polling = setInterval(async () => {
                await this.getActivities(this.record.id);
            }, 5000);
        }
    },
    async created() {
        if (this.record && this.record.id) {
            await this.getActivities(this.record.id);
        }
    },
    beforeDestroy() {
        clearInterval(this.polling);
        this.polling = null;
    }
}
</script>
